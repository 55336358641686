import React from 'react';
import { MerchantType, SelectedMerchants } from '../../../../reduxState/store/featuredMerchant/types';
import MerchantList from '../MerchantList';

export interface MerchantsProps {
  merchants: MerchantType[];
  selectedMerchants: SelectedMerchants;
  searchValue: string;
  setSelectedMerchants: (selectedMerchants: SelectedMerchants) => void;
  isLoading: boolean;
}

const Merchants = ({
  merchants,
  selectedMerchants,
  searchValue,
  setSelectedMerchants,
  isLoading,
}: MerchantsProps): JSX.Element => {
  if ('ErrorMessage' in merchants) return <div>No merchants found</div>;

  const filteredMerchants = merchants.filter(merchant => {
    return merchant.Name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const onSelect = (merchant: MerchantType): void => {
    const { ID: merchantID } = merchant;
    let merchantByID;
    const currentMerchantsByType = selectedMerchants.All;

    // If merchantID already exists, remove from the selectedMerchants list
    // Else, add to the list to toggle merchant checkbox
    if (currentMerchantsByType && currentMerchantsByType[merchantID]) {
      delete currentMerchantsByType[merchantID];
    } else {
      merchantByID = { [merchantID]: merchant };
    }

    setSelectedMerchants({
      ...selectedMerchants,
      All: { ...currentMerchantsByType, ...merchantByID },
    });
  };

  return (
    <MerchantList
      title={`Merchants (${filteredMerchants.length})`}
      merchants={filteredMerchants}
      onSelect={onSelect}
      selected={selectedMerchants.All || {}}
      isLoading={isLoading}
    />
  );
};

export default Merchants;
