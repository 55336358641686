import { toast } from 'react-toastify';
import { SERVER_ENDPOINT } from './constants';
import { Policy } from '../reduxState/store/applicationManagement/types';
import { MerchantSearchResult } from '../reduxState/store/performanceSummary/types';

export interface ErrorResponse {
  status: number;
  message: string;
}

const handleTooManyRequests = async (res: Response) => {
  if (res.status === 429) {
    toast.error("You've made too many requests in a short time. Please wait a moment and try again.");
  }
};

export const request = async (method: string, path: string, body?: Record<string, unknown>): Promise<Response> => {
  const auth0AccessToken = localStorage.getItem('accessToken');

  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-WF-UserAgent': 'partner-admin',
      Authorization: `Bearer ${auth0AccessToken}`,
    },
    credentials: 'include',
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`${SERVER_ENDPOINT}${path}`, options);
  handleTooManyRequests(response);
  return response;
};

export const searchMerchants = async (searchValue: string): Promise<MerchantSearchResult[]> => {
  try {
    const response = await request('GET', `/api/search?q=${encodeURIComponent(searchValue)}`);
    return await response.json();
  } catch (error) {
    console.error(error.message || error);
    return [];
  }
};

export const createTestTransactions = async (appId: number, trackingCode: string): Promise<any> => {
  const response = await request('POST', `/api/${appId}/application/test-transactions`, {
    applicationId: appId,
    trackingCode,
  });
  const responseJSON = await response.json();
  if (!response.ok) {
    throw new Error(responseJSON.ErrorMessage);
  }
  return responseJSON;
};
