import { pullAllBy } from 'lodash';
import { RootState } from 'reduxState';
import { AddConceptsArgs, Concept, GetConceptsArgs, RemoveConceptsArgs } from './types';
import api from '../api/slice';

interface GetConceptsResponse {
  isAllowList: boolean;
  concepts: {
    addable: Concept[];
    removable: Concept[];
  };
}

const conceptsApi = api.injectEndpoints({
  endpoints: builder => ({
    getConcepts: builder.query<GetConceptsResponse, GetConceptsArgs>({
      query: args => {
        return {
          url: `${args.appId}/concept`,
        };
      },
      providesTags: ['concepts'],
    }),
    addConcepts: builder.mutation<void, AddConceptsArgs>({
      query: args => {
        return {
          url: `${args.appId}/concept`,
          method: 'POST',
          body: { ...args.body, conceptIds: args.body.concepts?.map(c => c.id) },
        };
      },
      async onQueryStarted(arg, { getState, dispatch, queryFulfilled }) {
        const state = getState() as RootState;
        const { data } = conceptsApi.endpoints.getConcepts.select({ appId: arg.appId })(state);
        const isAllowList = data?.isAllowList;

        if (isAllowList === undefined) {
          throw new Error('missing isAllowList');
        }

        const patchResult = dispatch(
          conceptsApi.util.updateQueryData('getConcepts', { appId: arg.appId }, draft => {
            if (!arg.body.concepts) return;

            if (isAllowList) {
              draft.concepts.removable.unshift(...arg.body.concepts);
              pullAllBy(draft.concepts.addable, arg.body.concepts, 'id');
            } else {
              draft.concepts.addable.unshift(...arg.body.concepts);
              pullAllBy(draft.concepts.removable, arg.body.concepts, 'id');
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    removeConcepts: builder.mutation<void, RemoveConceptsArgs>({
      query: ({ appId, body }) => {
        return {
          url: `${appId}/concept`,
          method: 'DELETE',
          body: { ...body, conceptIds: body.concepts?.map(c => c.id) },
        };
      },
      async onQueryStarted(arg, { getState, dispatch, queryFulfilled }) {
        const state = getState() as RootState;
        const { data } = conceptsApi.endpoints.getConcepts.select({ appId: arg.appId })(state);
        const isAllowList = data?.isAllowList;

        if (isAllowList === undefined) {
          throw new Error('missing isAllowList');
        }

        const patchResult = dispatch(
          conceptsApi.util.updateQueryData('getConcepts', { appId: arg.appId }, draft => {
            if (!arg.body.concepts) return;

            if (isAllowList) {
              draft.concepts.addable.unshift(...arg.body.concepts);
              pullAllBy(draft.concepts.removable, arg.body.concepts, 'id');
            } else {
              draft.concepts.removable.unshift(...arg.body.concepts);
              pullAllBy(draft.concepts.addable, arg.body.concepts, 'id');
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetConceptsQuery, useAddConceptsMutation, useRemoveConceptsMutation } = conceptsApi;
export default conceptsApi;
