import { RestrictedUserRoles } from 'reduxState/store/user/constants';

export interface NavTab {
  name: string;
  pathname: string;
}

const performanceSummaryNavTab = {
  name: 'Performance',
  pathname: 'summary',
};

const commissionHistoryNavTab = {
  name: 'Commissions',
  pathname: 'commission-history',
};

const merchantsNavTab = {
  name: 'Merchants',
  pathname: 'merchants',
};

const appGroupPerformanceSummaryNavTab = {
  name: 'Performance',
  pathname: 'app-group-summary',
};

const appGroupCommissionHistoryNavTab = {
  name: 'Commissions',
  pathname: 'app-group-commission-history',
};

const appGroupPaymentsNavTab = {
  name: 'Payments',
  pathname: 'payment',
};

// Application nav tabs
const defaultNavigationTabs = [performanceSummaryNavTab, commissionHistoryNavTab, merchantsNavTab];
const operationsNavTabs = [commissionHistoryNavTab, merchantsNavTab];
const financeNavTabs = [performanceSummaryNavTab, commissionHistoryNavTab];

// App group nav tabs
const appGroupDefaultNavTabs = [
  appGroupPerformanceSummaryNavTab,
  appGroupCommissionHistoryNavTab,
  appGroupPaymentsNavTab,
];
const appGroupOperationsNavTabs = [appGroupCommissionHistoryNavTab];

const getRestrictedRoleTabs = (role: string, hasAppId: boolean): NavTab[] | undefined => {
  // Return application nav tabs
  if (hasAppId) {
    switch (role) {
      // Entity 74 Operations Team
      case RestrictedUserRoles.OPERATIONS:
        return operationsNavTabs;

      // Entity 74 Finance
      case RestrictedUserRoles.FINANCE:
        return financeNavTabs;

      // Entity 74 Business Team & Entity 74 Administrator
      case RestrictedUserRoles.BUSINESS:
      case RestrictedUserRoles.ADMIN: {
        return defaultNavigationTabs;
      }
    }
    // Return app group nav tabs
  } else {
    if (role === RestrictedUserRoles.OPERATIONS) return appGroupOperationsNavTabs;
    return appGroupDefaultNavTabs;
  }
};

export { getRestrictedRoleTabs, defaultNavigationTabs, appGroupDefaultNavTabs };
