import React, { FC, useState, useEffect } from 'react';
import { Button, Card, Icon } from '@mui/material';
import { subMonths, subYears } from 'date-fns';
import _ from 'lodash';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import MenuWithCheckboxes from 'components/Menu/MenuWithCheckboxes';
import { CheckboxOption } from 'components/Menu/MenuWithCheckboxes';
import { useAppSelector, useAppDispatch } from 'reduxState/hooks';
import { Application, AppGroup } from 'reduxState/store/application/types';
import {
  performanceSummaryDidUserChangeDatesSelector,
  performanceSummaryFilterValuesSelector,
} from 'reduxState/store/performanceSummary/selectors';
import { setFilterValues, setPerformanceSummaryDidUserChangeDates } from 'reduxState/store/performanceSummary/slice';
import exportIcon from 'static/images/export-icon-black.png';
import './AppGroupHeader.scss';

const getDefaultStartDate = (groupBy: string): string => {
  switch (groupBy) {
    case 'month': {
      return subYears(new Date(), 1).toString();
    }
    default: {
      // handles groupBy day/merchants
      return subMonths(new Date(), 1).toString();
    }
  }
};

interface AppGroupHeaderProps {
  appGroup: AppGroup;
  selectedApps: string[];
  viewByApplication: boolean;
  setViewByApplication: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedApps: React.Dispatch<React.SetStateAction<string[]>>;
  downloadCSVFile: () => void;
}

const AppGroupHeader: FC<React.PropsWithChildren<AppGroupHeaderProps>> = ({
  appGroup,
  selectedApps,
  viewByApplication,
  setViewByApplication,
  setSelectedApps,
  downloadCSVFile,
}) => {
  const [appOptions, setAppOptions] = useState<CheckboxOption[]>([]);
  const dispatch = useAppDispatch();
  const { startDate, endDate, groupBy } = useAppSelector(performanceSummaryFilterValuesSelector);
  const didUserChangeDates = useAppSelector(performanceSummaryDidUserChangeDatesSelector);

  useEffect(() => {
    if (_.isEmpty(appGroup)) return;
    const newAppOptions: CheckboxOption[] = [];
    const newSelectedApps: string[] = [];
    appGroup.applications.forEach((app: Application) => {
      newAppOptions.push({ label: app.name, value: app.id });
      newSelectedApps.push(app.id);
    });
    setAppOptions(newAppOptions);
    setSelectedApps(newSelectedApps);
  }, [appGroup, setSelectedApps]);

  const handleOnDateChange = (dateRange: DateRange | null) => {
    if (!dateRange) return;

    const [start, end] = dateRange;
    dispatch(
      setFilterValues({
        startDate: start.toDateString(),
        endDate: end.toDateString(),
      }),
    );
    dispatch(setPerformanceSummaryDidUserChangeDates(true));
  };

  const handleOnDateClear = () => {
    dispatch(
      setFilterValues({
        startDate: getDefaultStartDate(groupBy),
        endDate: new Date().toDateString(),
      }),
    );
    dispatch(setPerformanceSummaryDidUserChangeDates(false));
  };

  const handleOnAppSelect = (option: CheckboxOption) => {
    setSelectedApps(current =>
      current.includes(option.value) ? current.filter(v => v !== option.value) : [...current, option.value],
    );
  };

  const handleOnViewChange = (newGroupBy: 'day' | 'month' | 'application') => {
    let newStartDate = startDate;

    if (!didUserChangeDates) {
      newStartDate = getDefaultStartDate(newGroupBy);
    }

    if (newGroupBy === 'application') {
      setViewByApplication(true);
      setFilterValues({ startDate: newStartDate });
    } else {
      setViewByApplication(false);
      dispatch(
        setFilterValues({
          groupBy: newGroupBy,
          startDate: newStartDate,
        }),
      );
    }
  };

  const getViewByLabel = () => {
    if (viewByApplication) return 'View By Application';
    return `View By ${groupBy === 'day' ? 'Day' : 'Month'}`;
  };

  return (
    <Card className="app-group-header">
      <DateRangePicker
        label="Event Date"
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        onDateChange={handleOnDateChange}
        onDateClear={handleOnDateClear}
        className="app-group-date-picker"
      />
      <MenuWithCheckboxes
        label="Applications"
        options={appOptions}
        selectedOptions={selectedApps}
        onSelect={handleOnAppSelect}
        className="app-group-app-select-button"
      />
      <div className="app-group-button-container">
        <DropdownMenu
          className="app-group-action-button"
          id={'VIEW_BY_MENU'}
          label={getViewByLabel()}
          options={[
            { value: 'day', label: 'View By Day' },
            { value: 'month', label: 'View By Month' },
            { value: 'application', label: 'View By Application' },
          ]}
          handleOnChange={handleOnViewChange}
        />
        <Button
          className="progress-wrapper button-table-action app-group-export-button"
          onClick={downloadCSVFile}
          endIcon={
            <Icon>
              <img src={exportIcon} height={18} width={18} alt="Export icon" />
            </Icon>
          }
        >
          Export
        </Button>
      </div>
    </Card>
  );
};

export default AppGroupHeader;
