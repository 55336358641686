import { DATA_URLS } from 'helpers/constants';
import {
  FeaturedMerchant,
  FeaturedMerchantCategories,
  GetFeaturedMerchantQueryArgs,
  GetMerchantsForFeaturedMerchantQueryArgs,
  MerchantType,
  SaveAppFeaturedMerchantEditArgs,
  SaveAppSortOrderArgs,
} from './types';
import api from '../api/slice';

const featuredMerchantApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeaturedMerchant: builder.query<FeaturedMerchant, GetFeaturedMerchantQueryArgs>({
      query: ({ appId }) => {
        return {
          url: `${appId}/featured-merchant`,
        };
      },
      providesTags: ['featuredMerchant'],
      transformResponse: (
        response: { 'Application ID': string; 'Featured Merchants': FeaturedMerchantCategories },
        _,
        args,
      ) => {
        const PriorityIDs: number[] = response['Featured Merchants'].Priority || [];
        const GenericIDs: number[] = response['Featured Merchants'].Generic || [];

        const merchantMap: { [key: number]: MerchantType } = {};
        const merchants = args.merchants || [];

        merchants.forEach(merchant => {
          merchantMap[merchant.ID] = merchant;
        });

        const Priority = PriorityIDs
          ? PriorityIDs.map(id => {
              if (merchantMap[id]) return merchantMap[id];
              return { ID: id, Name: `${id} - Unavailable` };
            })
          : [];

        const Generic = GenericIDs
          ? GenericIDs.map(id => {
              if (merchantMap[id]) return merchantMap[id];
              return { ID: id, Name: `${id} - Unavailable` };
            })
          : [];

        return {
          Priority,
          Generic,
        };
      },
    }),
    getMerchantsForFeaturedMerchant: builder.query<MerchantType[], GetMerchantsForFeaturedMerchantQueryArgs>({
      queryFn: async arg => {
        try {
          const response = await fetch(DATA_URLS.CLOUD_DATA_BASE_URI + `/${arg.appId}/merchant/1`);
          const data = (await response.json()) as MerchantType[];

          return { data };
        } catch (error) {
          console.log(error);
          return { error };
        }
      },
    }),
    saveAppFeaturedMerchantEdit: builder.mutation<{ Success: number }, SaveAppFeaturedMerchantEditArgs>({
      query: args => {
        const { appId, body } = args;

        const formattedBody = {
          'Application ID': parseInt(appId),
          'Featured Merchants': {
            [body.type.toString()]: body.newFeaturedMerchantIdList,
          },
          Author: body.author,
        };

        return {
          url: `${args.appId}/featured-merchant`,
          method: 'PUT',
          body: formattedBody,
        };
      },
      invalidatesTags: ['featuredMerchant'],
    }),
    saveAppSortOrder: builder.mutation<{ Success: number }, SaveAppSortOrderArgs>({
      query: ({ appId, body }) => {
        const formattedBody = {
          'Application ID': parseInt(appId),
          'Featured Merchants': {
            Generic: body.genericFeaturedMerchants,
            Priority: body.priorityFeaturedMerchants,
          },
          Author: body.author,
        };

        return {
          url: `${appId}/featured-merchant`,
          method: 'PUT',
          body: formattedBody,
        };
      },
      invalidatesTags: ['featuredMerchant'],
    }),
  }),
});

export const {
  useGetFeaturedMerchantQuery,
  useGetMerchantsForFeaturedMerchantQuery,
  useLazyGetMerchantsForFeaturedMerchantQuery,
  useSaveAppFeaturedMerchantEditMutation,
  useSaveAppSortOrderMutation,
} = featuredMerchantApi;
export default featuredMerchantApi;
