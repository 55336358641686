import { isPayment } from './typeGuards';
import { PaymentHistory, PaymentDetail, GetPaymentHistoryArgs, GetPaymentDetailArgs } from './types';
import api from '../api/slice';

const paymentApi = api.injectEndpoints({
  endpoints: builder => ({
    getPaymentHistory: builder.query<PaymentHistory, GetPaymentHistoryArgs>({
      query: ({ appId }) => `${appId}/payment`,
      transformResponse: (paymentHistory: PaymentHistory) =>
        paymentHistory.filter(payment => isPayment(payment) && payment.Status === 'FINALIZED'),
    }),
    getPaymentDetail: builder.query<PaymentDetail, GetPaymentDetailArgs>({
      query: ({ appId, paymentId }) => `${appId}/payment/${paymentId}`,
    }),
  }),
});

export const {
  useGetPaymentHistoryQuery,
  useGetPaymentDetailQuery,
  useLazyGetPaymentDetailQuery,
  useLazyGetPaymentHistoryQuery,
} = paymentApi;
export default paymentApi;
