import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_ENDPOINT } from 'helpers/constants';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_ENDPOINT}/api/`,
    prepareHeaders(headers) {
      headers.set('X-WF-UserAgent', 'partner-admin');
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    },
    credentials: 'include',
  }),
  tagTypes: [
    'feeds',
    'boostedOffers',
    'commissionDownloads',
    'concepts',
    'featuredMerchant',
    'applicationCallback',
    'users',
    'applications',
  ],
  endpoints: () => ({}), // no endpoints will be declared here
});

export default api;
