import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  useGetFeaturedMerchantQuery,
  useLazyGetMerchantsForFeaturedMerchantQuery,
  useSaveAppFeaturedMerchantEditMutation,
  useSaveAppSortOrderMutation,
} from 'reduxState/store/featuredMerchant/api';
import {
  FeaturedMerchant,
  FeaturedMerchantEditTypes,
  FeaturedMerchantSaveTypes,
  MerchantsToSort,
  MerchantType,
  SelectedMerchants,
} from 'reduxState/store/featuredMerchant/types';
import AddOrRemoveButtons from './FeaturedMerchantButtons/AddOrRemoveButtons';
import FeaturedMerchantCategory from './FeaturedMerchantList/FeaturedMerchantCategory/FeaturedMerchantCategory';
import Merchants from './FeaturedMerchantList/Merchants/Merchants';
import Loading from '../Loading/Loading';
import './FeaturedMerchantListManager.scss';

export interface FeaturedMerchantListManagerProps {
  appId: string;
  searchValue: string;
}

const FeaturedMerchantListManager: FC<React.PropsWithChildren<FeaturedMerchantListManagerProps>> = ({
  appId,
  searchValue,
}) => {
  const initialSelectedMerchants = {
    Priority: {},
    Generic: {},
    All: {},
  };

  const initialFeaturedMerchants = {
    Priority: [],
    Generic: [],
  };

  const initialMerchantsToSort = { Priority: {}, Generic: {} };

  const [selectedMerchants, setSelectedMerchants] = useState<SelectedMerchants>(initialSelectedMerchants);
  const [featuredMerchants, setFeaturedMerchants] = useState<FeaturedMerchant>(initialFeaturedMerchants);
  const [merchantsToSort, setMerchantsToSort] = useState<MerchantsToSort>(initialMerchantsToSort);

  const { user } = useAuth0();

  const [
    triggerGetMerchants,
    { data: merchants, isFetching: isFetchingMerchants, error: merchantsError },
  ] = useLazyGetMerchantsForFeaturedMerchantQuery();

  if (!merchants && merchantsError) console.error(merchantsError);

  const {
    data: featuredMerchantData,
    isFetching: isFetchingFeaturedMerchants,
    error: featuredMerchantError,
  } = useGetFeaturedMerchantQuery({ appId, merchants: merchants || [] }, { skip: !merchants });

  if (!featuredMerchantData && featuredMerchantError) console.error(featuredMerchantError);

  const [
    saveAppFeaturedMerchantEdit,
    { isLoading: isUpdatingFeaturedMerchants },
  ] = useSaveAppFeaturedMerchantEditMutation();
  const [saveAppSortOrder, { isLoading: isUpdatingAllFeaturedMerchants }] = useSaveAppSortOrderMutation();

  const saveFeaturedMerchants = async (type: FeaturedMerchantEditTypes, saveType: FeaturedMerchantSaveTypes) => {
    const isRemoveSaveType = saveType === 'Remove';

    if (!featuredMerchantData) return;

    const currentFeaturedMerchants: MerchantType[] = isRemoveSaveType
      ? // FeaturedMerchant type also includes a boolean that is not related here
        (featuredMerchantData[type as keyof FeaturedMerchant] as MerchantType[]) || []
      : (featuredMerchantData[saveType as keyof FeaturedMerchant] as MerchantType[]) || [];

    // Creates an array of current featured merchant Ids
    const currentFeaturedMerchantIds: number[] = currentFeaturedMerchants.map(merchant => merchant.ID);
    // Creates an array of editable featured merchant Ids
    const featureMerchantIdsToEdit: number[] = Array.from(Object.keys(selectedMerchants[type] || []).map(Number));
    const currentAndUpdateIds = [...currentFeaturedMerchantIds, ...featureMerchantIdsToEdit];
    // Gets rid of duplicate values
    const uniqueAddableIds = currentAndUpdateIds.filter((id, index) => currentAndUpdateIds.indexOf(id) === index);

    const newFeaturedMerchantIdList = isRemoveSaveType
      ? currentFeaturedMerchantIds.filter(id => !featureMerchantIdsToEdit.includes(id))
      : uniqueAddableIds;

    try {
      saveAppFeaturedMerchantEdit({
        appId,
        body: {
          type: isRemoveSaveType ? type : saveType,
          newFeaturedMerchantIdList,
          author: user!.email || 'Unknown user',
        },
      }).unwrap();
    } catch (error) {
      console.error(error);
    }

    setSelectedMerchants(initialSelectedMerchants);
  };

  const saveSortOrder = () => {
    const genericFeaturedMerchants: number[] = featuredMerchants['Generic'].map(merchant => merchant.ID);
    const priorityFeaturedMerchants: number[] = featuredMerchants['Priority'].map(merchant => merchant.ID);

    try {
      saveAppSortOrder({
        appId,
        body: { priorityFeaturedMerchants, genericFeaturedMerchants, author: user!.email || 'Unknown author' },
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
    setMerchantsToSort(initialMerchantsToSort);
    setSelectedMerchants(initialSelectedMerchants);
  };

  const isLoading = () => {
    const allLoadingStates = [
      isFetchingMerchants,
      isFetchingFeaturedMerchants,
      isUpdatingFeaturedMerchants,
      isUpdatingAllFeaturedMerchants,
    ];
    // Returns true if any of the requests are still loading or fetching
    return allLoadingStates.some(isLoading => isLoading);
  };

  // Check if there are any selected merchants waiting to be sorted
  const noMerchantsToSort = isEmpty(merchantsToSort.Priority || {}) && isEmpty(merchantsToSort.Generic || {});

  useEffect(() => {
    // Trigger the query only once on component mount
    triggerGetMerchants({ appId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (featuredMerchantData) setFeaturedMerchants(featuredMerchantData);
  }, [featuredMerchantData]);

  if (!merchants) return null;

  return (
    <div className="featured-merchant-list-manager">
      <div className="merchant-body">
        <div className="tables merchant-table">
          <Merchants
            merchants={merchants}
            selectedMerchants={selectedMerchants}
            searchValue={searchValue}
            setSelectedMerchants={setSelectedMerchants}
            isLoading={isLoading()}
          />
        </div>
        <div className="button-container">
          <AddOrRemoveButtons
            onAdd={() => saveFeaturedMerchants('All', 'Priority')}
            onRemove={() => saveFeaturedMerchants('Priority', 'Remove')}
            isLoading={isLoading()}
          />
          <AddOrRemoveButtons
            onAdd={() => saveFeaturedMerchants('All', 'Generic')}
            onRemove={() => saveFeaturedMerchants('Generic', 'Remove')}
            isLoading={isLoading()}
          />
        </div>
        <div className="tables featured-tables">
          <div className="tables priority-merchant-table">
            <FeaturedMerchantCategory
              appId={appId}
              featuredMerchants={featuredMerchants}
              featuredMerchantType={'Priority'}
              isLoading={isLoading()}
              merchantsToSort={merchantsToSort}
              searchValue={searchValue}
              selectedMerchants={selectedMerchants}
              setFeaturedMerchants={setFeaturedMerchants}
              setMerchantsToSort={setMerchantsToSort}
              setSelectedMerchants={setSelectedMerchants}
            />
          </div>
          <div className="tables generic-merchant-table">
            <FeaturedMerchantCategory
              appId={appId}
              featuredMerchants={featuredMerchants}
              featuredMerchantType={'Generic'}
              isLoading={isLoading()}
              merchantsToSort={merchantsToSort}
              searchValue={searchValue}
              selectedMerchants={selectedMerchants}
              setFeaturedMerchants={setFeaturedMerchants}
              setMerchantsToSort={setMerchantsToSort}
              setSelectedMerchants={setSelectedMerchants}
            />
          </div>
        </div>
      </div>
      <div className="merchant-footer">
        <Button variant="contained" color="primary" disabled={isLoading() || noMerchantsToSort} onClick={saveSortOrder}>
          Save
        </Button>
        {isLoading() && <Loading size={24} className="save-loading" />}
      </div>
    </div>
  );
};

export default FeaturedMerchantListManager;
