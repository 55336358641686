import {
  ApplicationManagement,
  GetApplicationManagementArgs,
  UpdateApplicationManagementArgs,
  Feed,
  Policy,
} from './types';
import api from '../api/slice';

const applicationManagementApi = api.injectEndpoints({
  endpoints: builder => ({
    getApplicationManagement: builder.query<ApplicationManagement, GetApplicationManagementArgs>({
      query: ({ appId }) => `${appId}/application/management`,
    }),
    updateApplicationManagement: builder.mutation<{ rowsAffected: number }, UpdateApplicationManagementArgs>({
      query: ({ appId, applicationManagement, author }) => ({
        url: `${appId}/application/management`,
        method: 'PUT',
        body: {
          ...applicationManagement,
          Author: author,
        },
      }),
    }),
    getFeeds: builder.query<Feed[], string>({
      query: appId => `${appId}/application/feed`,
      providesTags: ['feeds'],
      transformResponse: (response: Feed[]) => response.sort((a, b) => a.kind.localeCompare(b.kind)),
    }),
    updateFeeds: builder.mutation<void, { appId: string; feeds: Feed[] }>({
      query: ({ appId, feeds }) => ({
        url: `${appId}/application/feed`,
        method: 'PATCH',
        body: feeds,
      }),
      invalidatesTags: ['feeds'],
    }),
    getSplitPolicyOptions: builder.query<Policy[], void>({
      query: () => 'app-creation/policy',
    }),
  }),
});

export const {
  useGetApplicationManagementQuery,
  useUpdateApplicationManagementMutation,
  useGetFeedsQuery,
  useUpdateFeedsMutation,
  useGetSplitPolicyOptionsQuery,
} = applicationManagementApi;
export default applicationManagementApi;
