import {
  AdminApplication,
  ApplicationFromApi,
  ApplicationCallback,
  GetApplicationByIdArgs,
  UpdateApplicationCallbackArgs,
  GetApplicationsReturnType,
  AppGroup,
  CreateAdminApplicationArgs,
  CreateClientApplicationArgs,
} from './types';
import api from '../api/slice';

const applicationsApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserApplications: builder.query<GetApplicationsReturnType, void>({
      query: () => '/application',
      transformResponse: (response: AppGroup[]) => {
        return {
          appGroups: response,
          applications: response.map(appGroup => appGroup.applications).flat(),
        };
      },
    }),
    getApplicationDetails: builder.query<ApplicationFromApi, GetApplicationByIdArgs>({
      providesTags: (result, error, arg) => [{ type: 'applications', id: arg.appId }],
      query: ({ appId }) => {
        return {
          url: `${appId}/application`,
        };
      },
    }),
    getAllAdminApplications: builder.query<AdminApplication[], void>({
      query: () => ({
        url: '/app-creation/admin',
      }),
    }),
    getApplicationCallback: builder.query<ApplicationCallback, { appId: string }>({
      query: ({ appId }) => {
        return {
          url: `${appId}/application/callback`,
        };
      },
      providesTags: ['applicationCallback'],
    }),
    updateApplicationCallback: builder.mutation<{ RowsAffected: number }, UpdateApplicationCallbackArgs>({
      query: ({ appId, body }) => {
        return {
          url: `${appId}/application/callback`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['applicationCallback'],
    }),
    createAdminApplication: builder.mutation<{ applicationId: number }, CreateAdminApplicationArgs>({
      query: args => {
        return {
          url: '/app-creation/admin',
          method: 'POST',
          body: args.body,
        };
      },
    }),
    createClientApplication: builder.mutation<{ applicationId: number }, CreateClientApplicationArgs>({
      query: args => {
        return {
          url: '/app-creation/client',
          method: 'POST',
          body: args.body,
        };
      },
    }),
  }),
});

export const {
  useGetUserApplicationsQuery,
  useGetApplicationDetailsQuery,
  useGetAllAdminApplicationsQuery,
  useGetApplicationCallbackQuery,
  useUpdateApplicationCallbackMutation,
  useCreateAdminApplicationMutation,
  useCreateClientApplicationMutation,
} = applicationsApi;
export default applicationsApi;
