import { InviteApplicationMemberArg, SendPasswordResetArg, RemoveApplicationMemberArg, UserWithRoles } from './types';
import api from '../api/slice';

const userApi = api.injectEndpoints({
  endpoints: builder => ({
    inviteApplicationMember: builder.mutation<void, InviteApplicationMemberArg>({
      query: arg => ({
        url: `${arg.appId}/user/invite`,
        method: 'POST',
        body: {
          email: arg.email,
          permissionRoles: arg.permissionRoles,
        },
      }),
      invalidatesTags: ['users'],
    }),
    sendPasswordResetEmail: builder.query<void, SendPasswordResetArg>({
      query: arg => `${arg.appId}/user/reset-password/${arg.userId}`,
    }),
    removeApplicationMember: builder.mutation<void, RemoveApplicationMemberArg>({
      query: arg => ({
        url: `${arg.appId}/user/${arg.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    listApplicationMembers: builder.query<UserWithRoles[], { appId: string }>({
      query: arg => `${arg.appId}/user`,
      providesTags: ['users'],
    }),
  }),
});

export const {
  useInviteApplicationMemberMutation,
  useListApplicationMembersQuery,
  useRemoveApplicationMemberMutation,
  useLazySendPasswordResetEmailQuery,
} = userApi;
export default userApi;
