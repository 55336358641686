import React, { useState, useEffect } from 'react';
import { Clear } from '@mui/icons-material';
import { Checkbox, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import Concept from './Concept';
import { selectedConceptsType } from './ConceptManager';
import { filterConcepts } from './util';
import { Concept as ConceptType } from '../../reduxState/store/concept/types';

export interface ConceptListProps {
  title: string;
  concepts: ConceptType[];
  selectedConcepts: selectedConceptsType;
  setSelectedConcepts: (selected: selectedConceptsType) => void;
  isLoading: boolean;
  isSaving: boolean;
}

const ConceptList = ({
  title,
  concepts,
  selectedConcepts,
  setSelectedConcepts,
  isLoading,
  isSaving,
}: ConceptListProps): JSX.Element => {
  const [filter, setFilter] = useState('');

  const allSelected = selectedConcepts.size !== 0 && selectedConcepts.size === concepts.length;
  const filteredConcepts = filterConcepts(concepts, filter);
  const toggleSelectConcept = (concept: ConceptType) => {
    const newState = new Map(selectedConcepts);

    if (newState.has(concept.id)) {
      newState.delete(concept.id);
    } else {
      newState.set(concept.id, concept);
    }

    setSelectedConcepts(newState);
  };

  const toggleSelectAllConcepts = () => {
    if (allSelected) {
      setSelectedConcepts(new Map());
      return;
    }

    if (filter.length > 0 && selectedConcepts.size > 0) {
      setSelectedConcepts(new Map());
      return;
    }

    const all: selectedConceptsType = filteredConcepts.reduce((acc: selectedConceptsType, concept) => {
      return acc.set(concept.id, concept);
    }, new Map());

    setSelectedConcepts(all);
  };

  useEffect(() => {
    if (isSaving && selectedConcepts.size > 0) {
      setSelectedConcepts(new Map());
      setFilter('');
    }
  }, [isSaving, selectedConcepts, setSelectedConcepts]);

  return (
    <div className="flex flex-1 flex-col">
      <h3>{`${title} (${selectedConcepts.size}/${concepts.length})`}</h3>
      <TextField
        label="Filter"
        variant="standard"
        value={filter}
        onChange={(e): void => setFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Checkbox
                color="default"
                checked={allSelected}
                indeterminate={selectedConcepts.size > 0 && !allSelected}
                onChange={toggleSelectAllConcepts}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="clear filter" size="small" edge="end" onClick={() => setFilter('')}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {(isLoading || isSaving) && <LinearProgress />}
      {/* per autosizer docs; otherwise grows larger than the page */}
      <div className="flex-auto overflow-hidden">
        {!isLoading && filter.length > 0 && filteredConcepts.length === 0 && (
          <div className="text-center mt-40 w-full">
            <Typography>No concepts match your filter</Typography>
          </div>
        )}
        <AutoSizer>
          {/* @ts-ignore */}
          {({ height, width }): JSX.Element => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={32}
              itemCount={filteredConcepts.length}
              itemData={filteredConcepts}
            >
              {(props): JSX.Element => (
                <Concept
                  {...props}
                  selectedConcepts={selectedConcepts}
                  onSelect={(concept: ConceptType) => toggleSelectConcept(concept)}
                />
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default ConceptList;
