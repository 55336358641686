import React from 'react';
import { Delete, ForwardToInbox, DoneOutline } from '@mui/icons-material';
import { TableCell, TableRow, Button, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Loading from 'components/Loading/Loading';
import { findErrorMessage } from 'helpers';
import { USERS_IDENTITIES_MAP } from 'helpers/auth0';
import { useLazySendPasswordResetEmailQuery, useRemoveApplicationMemberMutation } from 'reduxState/store/user/api';
import { UserWithRoles } from 'reduxState/store/user/types';

interface Props {
  appId: string;
  user: UserWithRoles;
}

const UserRow = ({ appId, user }: Props) => {
  const [removeUser, { isLoading: isRemovingUser }] = useRemoveApplicationMemberMutation();
  const [
    sendPasswordResetEmail,
    { isLoading: isEmailSending, isSuccess: emailSent },
  ] = useLazySendPasswordResetEmailQuery();

  const handleRemoveUser = async () => {
    const confirmedToRemove = window.confirm(`Are you sure you want to remove all access for ${user!.email!}`);

    if (!confirmedToRemove) {
      return;
    }

    try {
      await removeUser({ appId, userId: user.id }).unwrap();
      toast.success('User removed');
    } catch (error) {
      toast.error(findErrorMessage(error));
    }
  };

  const handleSendPasswordResetEmail = async () => {
    try {
      await sendPasswordResetEmail({ appId, userId: user.id });
      toast.success('Email sent');
    } catch (error) {
      toast.error(findErrorMessage(error));
    }
  };

  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell>{user.identities.map(item => USERS_IDENTITIES_MAP[item.connection] || item).join(', ')}</TableCell>
      <TableCell>
        {user.roles
          .map(val => val.name)
          .find(name => {
            return name === 'owner' || name === 'user';
          })}
      </TableCell>
      <TableCell>
        <Button disabled={isRemovingUser} onClick={() => handleRemoveUser()}>
          {isRemovingUser ? <Loading size={20} /> : <Delete />}
        </Button>
      </TableCell>
      <TableCell>
        <Tooltip title={emailSent ? 'Email sent!' : 'Send Reset Password Email'} placement="right" arrow={true}>
          <Button disabled={isEmailSending} onClick={() => handleSendPasswordResetEmail()}>
            {isEmailSending && <Loading size={20} />}
            {emailSent && <DoneOutline />}
            {!isEmailSending && !emailSent && <ForwardToInbox />}
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
