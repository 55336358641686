import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import PaymentReportDownload from 'components/Payment/PaymentReportDownload/PaymentReportDownload';
import { useAppSelector } from 'reduxState/hooks';
import { useGetUserApplicationsQuery } from 'reduxState/store/application/api';
import { AppGroup } from 'reduxState/store/application/types';
import { useGetPaymentHistoryQuery } from 'reduxState/store/payment/api';
import { RestrictedUserRoles } from 'reduxState/store/user/constants';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import './Payment.scss';

const Payment: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const adminAppId = params.adminAppId ?? '';
  const { data: paymentHistory, isFetching, isError } = useGetPaymentHistoryQuery({ appId: adminAppId });

  const { data: applicationsData, isFetching: isApplicationDataFetching } = useGetUserApplicationsQuery();
  const allAppGroups: AppGroup[] = applicationsData?.appGroups || [];
  const appGroup =
    allAppGroups.find((appGroup: AppGroup) => appGroup.adminId.toString() === adminAppId) || ({} as AppGroup);
  const restrictedRole = useAppSelector(selectUserRestrictedRole);
  const isOperations = restrictedRole === RestrictedUserRoles.OPERATIONS;

  if (isOperations) {
    navigate('/');
    return <></>;
  }

  if (isError) {
    return <Error />;
  }

  if (isFetching || isApplicationDataFetching || !paymentHistory) {
    return <Loading />;
  }

  return (
    <div className="payment">
      <div className="payment-header">
        <h1 className="text-muted-dark-purple">Payment Detail Reports</h1>
      </div>
      {paymentHistory.length
        ? paymentHistory.map(payment => (
            <PaymentReportDownload appGroup={appGroup} key={payment.PaymentID} payment={payment} />
          ))
        : 'No payments to display'}
    </div>
  );
};

export default Payment;
