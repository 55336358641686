import React, { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FeaturedMerchantListManager from 'components/FeaturedMerchantListManager/FeaturedMerchantListManager';
import './FeaturedMerchant.scss';

export interface FeaturedMerchantProps {
  appId: string;
}

const FeaturedMerchant: FC<React.PropsWithChildren<FeaturedMerchantProps>> = ({ appId }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div className="featured-merchant">
      <h3 className="text-muted-dark-purple">Featured Merchant Administration</h3>
      <TextField
        className="table-filter-field featured-merchant-table-filter-field"
        variant="outlined"
        placeholder="Search merchants"
        InputProps={{
          endAdornment:
            searchTerm === '' ? (
              <SearchIcon />
            ) : (
              <IconButton onClick={() => setSearchTerm('')}>
                <CloseIcon />
              </IconButton>
            ),
        }}
        onChange={e => setSearchTerm(e.target.value)}
        value={searchTerm}
      />

      <FeaturedMerchantListManager appId={appId} searchValue={searchTerm} />
    </div>
  );
};

export default FeaturedMerchant;
