import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { findErrorMessage } from 'helpers';
import { request } from 'helpers/api';
import { AppStats } from 'reduxState/store/performanceSummary/types';
import {
  BaseAppPerformanceSummaryParams,
  PerformanceSummaryByDate,
  PerformanceSummaryGroupedByMerchant,
  SelectedMerchantPerformanceSummary,
  GetAppStatsByDateParams,
  GetSelectedMerchantStatsParams,
} from './types';

export const getAppPerformanceSummariesByDate = async (
  appId: string,
  adminAppId: string,
  params: GetAppStatsByDateParams,
): Promise<PerformanceSummaryByDate[]> => {
  const { groupBy, sortOrder, sortBy } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const query = `application_id=${appId}&group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}&sort_order=${sortOrder}&sort_by=${sortBy}`;

  try {
    const response = await request('GET', `/api/${adminAppId}/performance?${query}`);
    const data = (await response.json()) as AppStats[];

    if (data.length === 0) {
      return [];
    }

    if (data.length > 1) {
      throw new Error(`expected to recieve stats for 1 application but got stats for ${data.length}`);
    }

    return data[0].Stats;
  } catch (error) {
    throw new Error(findErrorMessage(error) || 'Failed to retrieve Performance Summaries by date.');
  }
};

export const getAppPerformanceSummariesGroupedByMerchant = async (
  appId: string,
  params: BaseAppPerformanceSummaryParams,
): Promise<PerformanceSummaryGroupedByMerchant[]> => {
  const { sortOrder, sortBy } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const query = `start_date=${startDate}&end_date=${endDate}&sort_order=${sortOrder}&sort_by=${sortBy}`;

  try {
    const response = await request('GET', `/api/${appId}/performance/merchant?${query}`);
    const data = response.json();

    return data;
  } catch (error) {
    throw new Error(findErrorMessage(error) || 'Failed to retrieve Performance Summaries grouped by merchants.');
  }
};

export const getSelectedMerchantPerformanceSummary = async (
  appId: string,
  params: GetSelectedMerchantStatsParams,
): Promise<SelectedMerchantPerformanceSummary[]> => {
  const { groupBy, sortOrder, sortBy, merchantId } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const query = `group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}&sortBy=${
    sortBy !== 'merchant_name' ? sortBy : ''
  }&sort_order=${sortOrder}`;

  try {
    const response = await request('GET', `/api/${appId}/performance/merchant/${merchantId}?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(findErrorMessage(error) || `Failed to retrieve stats for selected merchant.`);
  }
};
