import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import { AppGroup, Application } from 'reduxState/store/application/types';
import './AppButtonsList.scss';

/**
 * Represents a list of buttons with application names per button.  On click, it will navigate the user to the Application page of the specified app.
 *
 * @param props - The properties for the AppButton component.
 * @param props.appGroup - The App Group that contains all the applications and admin app id
 *
 * @example
 * ```tsx
 * <AppButtonsList
 *  appGroup={{
 *  adminId: '47`
 *  adminName: 'Acorns`
 *  applications: [{
 *  id: 46,
 *  name: 'Acorns Chrome Extension Client',
 *  partnerId: 'acorns',
 *  paymentName: 'Acorns Advisers, LLC'
 * }]
 * }}
 * />
 * ```
 */

interface AppButtonsListProps {
  appGroup: AppGroup;
  isRestrictedUser?: boolean; // for entity 74 ops restricted user
}

const AppButtonsList: FC<React.PropsWithChildren<AppButtonsListProps>> = ({ appGroup, isRestrictedUser }) => {
  const apps = appGroup.applications || [];
  const { scrollContainerRef, canScrollLeft, canScrollRight, scroll, checkForScroll } = useHorizontalScroll();

  useEffect(checkForScroll, [apps, checkForScroll]);

  return (
    <div className="app-button-list-scroll-icon-boundary">
      {canScrollLeft && (
        <IconButton
          className="scroll-icon left"
          onClick={() => scroll(-400)}
          sx={{
            ':hover': {
              backgroundColor: 'var(--dark-purple)',
            },
          }}
        >
          <ChevronLeftIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      {canScrollRight && (
        <IconButton
          className="scroll-icon right"
          onClick={() => scroll(400)}
          sx={{
            ':hover': {
              backgroundColor: 'var(--dark-purple)',
            },
          }}
        >
          <ChevronRightIcon sx={{ color: 'white' }} />
        </IconButton>
      )}

      <div
        ref={scrollContainerRef}
        className={`app-buttons-list ${canScrollLeft || canScrollRight ? 'horizontal-scroll' : ''}`}
      >
        {apps.map((app: Application) => {
          return (
            <Link
              key={`${app.name}`}
              className={`app-button`}
              to={`/${appGroup.adminId}/app/${app.id}/${isRestrictedUser ? 'commission-history' : 'summary'}`}
            >
              {app.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default AppButtonsList;
