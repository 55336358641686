import { PerformanceSummaryByDate } from 'api/performanceSummary/types';
import { DATE_FORMAT } from 'helpers/constants';
import { formatUTCDate } from 'helpers/DateHelpers';
import { AppStats } from 'reduxState/store/performanceSummary/types';

export interface DateInterval {
  start: Date;
  end: Date;
}

interface BasePerformanceSummaryReport {
  BaseCommissionAmount: number;
  BoostedCommissionAmount: number;
  GrossCommissionAmount: number;
  GrossSalesAmount: number;
  NetSalesAmount: number;
  NetCommissionAmount: number;
  OrderCount: number;
  ShortURLsCreated: number;
  DevicesCreated: number;
  WildlinkClicks: number;
  ExtensionInstalls: number;
  ExtensionUninstalls: number;
}

export interface PerformanceSummaryReport extends BasePerformanceSummaryReport {
  ReportDate: string;
}

export interface PerformanceSummaryReportSumByApp extends BasePerformanceSummaryReport {
  ApplicationName: string;
}

interface TotalPerformanceSummary {
  [reportDate: string]: PerformanceSummaryReport;
}

const PerformanceSummaryReportSortByProperties: Record<string, keyof BasePerformanceSummaryReport> = {
  gross_commission_amount: 'GrossCommissionAmount',
  gross_sales_amount: 'GrossSalesAmount',
  net_commission_amount: 'NetCommissionAmount',
  net_sales_amount: 'NetSalesAmount',
  base_commission_amount: 'BaseCommissionAmount',
  enhanced_commission_amount: 'BoostedCommissionAmount', // Boosted Commission Amount
  order_count: 'OrderCount',
  shorturls_created: 'ShortURLsCreated',
  wildlink_clicks: 'WildlinkClicks',
  extension_installs: 'ExtensionInstalls',
  extension_uninstalls: 'ExtensionUninstalls',
  devices_created: 'DevicesCreated',
};

const defaultReport = {
  GrossCommissionAmount: 0,
  GrossSalesAmount: 0,
  BaseCommissionAmount: 0,
  NetSalesAmount: 0,
  BoostedCommissionAmount: 0,
  NetCommissionAmount: 0,
  ShortURLsCreated: 0,
  DevicesCreated: 0,
  WildlinkClicks: 0,
  ExtensionInstalls: 0,
  ExtensionUninstalls: 0,
  OrderCount: 0,
};

const basePerformanceSummaryReportKeys = [
  'BaseCommissionAmount',
  'BoostedCommissionAmount',
  'GrossCommissionAmount',
  'GrossSalesAmount',
  'NetSalesAmount',
  'NetCommissionAmount',
  'OrderCount',
  'ShortURLsCreated',
  'DevicesCreated',
  'WildlinkClicks',
  'ExtensionInstalls',
  'ExtensionUninstalls',
];

export const isPerformanceSummaryReportKey = (key: string): key is keyof PerformanceSummaryReport => {
  const performanceSummaryReportKeys = [...basePerformanceSummaryReportKeys, 'ReportDate'];
  return performanceSummaryReportKeys.includes(key);
};

export const isPerformanceSummaryReportSumByAppKey = (key: string): key is keyof PerformanceSummaryReportSumByApp => {
  const performanceSummaryReportSumByAppKeys = [...basePerformanceSummaryReportKeys, 'ApplicationName'];
  return performanceSummaryReportSumByAppKeys.includes(key);
};

export function getPrevInterval(dateRange: DateInterval): DateInterval {
  const { start, end } = dateRange;
  // we need to add a day here to account for the fact that this is subtracting from a specific time in each day.
  // ex: Wednesday at 1pm - Monday at 1pm would have a difference of 2 days, even though Monday - Wednesday is 3 days.
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const difference = end.getTime() - start.getTime() + oneDayInMilliseconds;

  return {
    start: new Date(start.getTime() - difference),
    end: new Date(start.getTime() - oneDayInMilliseconds), // end date should be one day prior to start date
  };
}

export const flattenAppStats = (
  appStats: AppStats[],
  sortOrder: 'asc' | 'desc',
  sortBy?: string,
): PerformanceSummaryReport[] => {
  if (appStats.length === 0) return [];

  const combinedAppStats = appStats.reduce((acc: TotalPerformanceSummary, appPerformanceSummary: AppStats) => {
    appPerformanceSummary.Stats.forEach((stat: PerformanceSummaryByDate) => {
      const reportDate = formatUTCDate(new Date(stat.ReportDate), DATE_FORMAT);

      if (!acc[reportDate]) {
        acc[reportDate] = {
          ...defaultReport,
          ReportDate: stat.ReportDate,
        };
      }

      acc[reportDate].GrossSalesAmount += parseFloat(stat.GrossSalesAmount);
      acc[reportDate].GrossCommissionAmount += parseFloat(stat.GrossCommissionAmount);
      acc[reportDate].NetSalesAmount += parseFloat(stat.NetSalesAmount);
      acc[reportDate].BaseCommissionAmount += parseFloat(stat.BaseCommissionAmount);
      acc[reportDate].BoostedCommissionAmount += parseFloat(stat.BoostedCommissionAmount);
      acc[reportDate].NetCommissionAmount += parseFloat(stat.NetCommissionAmount);
      acc[reportDate].ShortURLsCreated += parseFloat(stat.ShortURLsCreated.toString());
      acc[reportDate].DevicesCreated += parseFloat(stat.DevicesCreated.toString());
      acc[reportDate].WildlinkClicks += parseFloat(stat.WildlinkClicks.toString());
      acc[reportDate].ExtensionInstalls += parseFloat(stat.ExtensionInstalls.toString());
      acc[reportDate].ExtensionUninstalls += parseFloat(stat.ExtensionUninstalls.toString());
      acc[reportDate].OrderCount += parseFloat(stat.OrderCount.toString());
    });
    return acc;
  }, {} as TotalPerformanceSummary);

  const combinedAppStatsArray = Object.values(combinedAppStats);

  // we need to sort manually as its possible that the combined values make the array unsorted
  if (sortBy) {
    const propertyToSortBy: keyof PerformanceSummaryReport = PerformanceSummaryReportSortByProperties[sortBy] || '';

    // guard to return sortBy Report date if property doesn't exist
    if (!propertyToSortBy) return combinedAppStatsArray;

    return combinedAppStatsArray.sort((a: PerformanceSummaryReport, b: PerformanceSummaryReport) => {
      const aValue = a[propertyToSortBy];
      const bValue = b[propertyToSortBy];

      if (aValue === bValue) return 0;

      return sortOrder === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
    });
  }

  return combinedAppStatsArray;
};

export const getPerformanceSummarySumByApp = (
  appStats: AppStats[],
  sortOrder: 'asc' | 'desc',
  sortBy?: string,
): PerformanceSummaryReportSumByApp[] => {
  if (!appStats.length) return [];
  const sumAppStats = appStats.map((appStat: AppStats) => {
    return appStat.Stats.reduce(
      (acc: PerformanceSummaryReportSumByApp, appPerformanceSummary: PerformanceSummaryByDate) => {
        if (!acc.ApplicationName) {
          acc = {
            ...defaultReport,
            ApplicationName: appStat.ApplicationName,
          };
        }

        acc.GrossSalesAmount += parseFloat(appPerformanceSummary.GrossSalesAmount);
        acc.GrossCommissionAmount += parseFloat(appPerformanceSummary.GrossCommissionAmount);
        acc.NetSalesAmount += parseFloat(appPerformanceSummary.NetSalesAmount);
        acc.BaseCommissionAmount += parseFloat(appPerformanceSummary.BaseCommissionAmount);
        acc.BoostedCommissionAmount += parseFloat(appPerformanceSummary.BoostedCommissionAmount);
        acc.NetCommissionAmount += parseFloat(appPerformanceSummary.NetCommissionAmount);
        acc.ShortURLsCreated += parseFloat(appPerformanceSummary.ShortURLsCreated.toString());
        acc.DevicesCreated += parseFloat(appPerformanceSummary.DevicesCreated.toString());
        acc.WildlinkClicks += parseFloat(appPerformanceSummary.WildlinkClicks.toString());
        acc.ExtensionInstalls += parseFloat(appPerformanceSummary.ExtensionInstalls.toString());
        acc.ExtensionUninstalls += parseFloat(appPerformanceSummary.ExtensionUninstalls.toString());
        acc.OrderCount += parseFloat(appPerformanceSummary.OrderCount.toString());

        return acc;
      },
      {} as PerformanceSummaryReportSumByApp,
    );
  });

  if (sortBy) {
    const propertyToSortBy: keyof BasePerformanceSummaryReport = PerformanceSummaryReportSortByProperties[sortBy] || '';

    if (!propertyToSortBy) return sumAppStats;

    return sumAppStats.sort((a: PerformanceSummaryReportSumByApp, b: PerformanceSummaryReportSumByApp) => {
      const aValue = a[propertyToSortBy];
      const bValue = b[propertyToSortBy];

      if (aValue === bValue) return 0;

      return sortOrder === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
    });
  }

  return sumAppStats;
};
