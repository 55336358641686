import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { sortBy } from 'lodash';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';

import { usePathParam } from 'hooks/usePathParam';
import { useListApplicationMembersQuery } from 'reduxState/store/user/api';
import UserRow from './UserRow';

const UsersTable = () => {
  const adminAppId = usePathParam('adminAppId');
  const users = useListApplicationMembersQuery({ appId: adminAppId });

  if (users.isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Loading />
      </div>
    );
  }

  if (users.isError) {
    return <Error retry={users.refetch} />;
  }

  if (users.isSuccess && users.data.length === 0) {
    return <div className="flex min-h-[100px] items-center justify-center">No users found.</div>;
  }

  return (
    <TableContainer className="max-w-[600px]">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="font-bold">Current Users</TableCell>
            <TableCell className="font-bold">Auth Method</TableCell>
            <TableCell className="font-bold">Role</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortBy(users.data, 'email', 'asc').map(user => {
            return <UserRow key={user.id} appId={adminAppId} user={user} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
