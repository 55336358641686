import React, { FC, useMemo, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import { getDurationDescription } from './constants';
import StatCard from './StatCard';
import { PossiblePerformanceSummaryTypes } from '../PerformanceSummary/types';
import './StatCards.scss';

interface StatCardProps {
  startDate: Date;
  endDate: Date;
  stats: PossiblePerformanceSummaryTypes[];
  prevIntervalStats: PossiblePerformanceSummaryTypes[];
  currency: string;
  horizontalScroll?: boolean;
}

interface TotalReport {
  [key: string]: number;
}

const defaultReport = Object.freeze({
  GrossCommissionAmount: 0,
  GrossSalesAmount: 0,
  ExtensionInstalls: 0,
  OrderCount: 0,
  WildlinkClicks: 0,
});

const StatCards: FC<React.PropsWithChildren<StatCardProps>> = ({
  startDate,
  endDate,
  stats,
  currency,
  prevIntervalStats,
  horizontalScroll,
}) => {
  const { scrollContainerRef, canScrollLeft, canScrollRight, scroll, checkForScroll } = useHorizontalScroll();
  const durationDescription = getDurationDescription(startDate, endDate);

  const getIntervalReport = (totals: TotalReport, record: PossiblePerformanceSummaryTypes) => {
    for (const key in totals) {
      switch (key) {
        case 'GrossCommissionAmount':
        case 'GrossSalesAmount':
        case 'OrderCount': {
          if (record[key]) totals[key] += Number(record[key]);
          break;
        }

        // The Following properties only exist in ConvertedPerformanceSummary/PerformanceSummaryByDate
        case 'ExtensionInstalls': {
          if ('ExtensionInstalls' in record) totals[key] += Number(record['ExtensionInstalls']);
          break;
        }
        case 'WildlinkClicks': {
          if ('WildlinkClicks' in record) totals[key] += Number(record['WildlinkClicks']);
          break;
        }
      }
    }
    return totals;
  };

  const currentIntervalReport = useMemo(
    () => (Array.isArray(stats) ? stats.reduce(getIntervalReport, { ...defaultReport }) : defaultReport),
    [stats],
  );

  const prevIntervalReport = useMemo(
    () =>
      Array.isArray(prevIntervalStats)
        ? prevIntervalStats.reduce(getIntervalReport, { ...defaultReport })
        : defaultReport,
    [prevIntervalStats],
  );

  useEffect(() => {
    horizontalScroll && checkForScroll();
  }, [stats, prevIntervalStats, horizontalScroll, checkForScroll]);

  return (
    <div className="scroll-icon-boundary">
      {horizontalScroll && (
        <>
          {canScrollLeft && (
            <IconButton
              className="scroll-icon left"
              onClick={() => scroll(-400)}
              sx={{
                ':hover': {
                  backgroundColor: 'var(--dark-purple)',
                },
              }}
            >
              <ChevronLeftIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          {canScrollRight && (
            <IconButton
              className="scroll-icon right"
              onClick={() => scroll(400)}
              sx={{
                ':hover': {
                  backgroundColor: 'var(--dark-purple)',
                },
              }}
            >
              <ChevronRightIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
        </>
      )}
      <div
        ref={scrollContainerRef}
        className={`stat-card-container ${horizontalScroll ? 'horizontal-scroll' : ''}`}
        data-testid="stat-cards"
      >
        <StatCard
          title="Gross Commissions"
          type="dollar"
          currency={currency}
          currentVal={currentIntervalReport.GrossCommissionAmount}
          previousVal={prevIntervalReport.GrossCommissionAmount}
          durationDescription={durationDescription}
        />
        <StatCard
          title="Gross Sales"
          type="dollar"
          currency={currency}
          currentVal={currentIntervalReport.GrossSalesAmount}
          previousVal={prevIntervalReport.GrossSalesAmount}
          durationDescription={durationDescription}
        />
        <StatCard
          title="AVG Commission Rate"
          type="percentage"
          currentVal={(currentIntervalReport.GrossCommissionAmount / currentIntervalReport.GrossSalesAmount) * 100 || 0}
          previousVal={(prevIntervalReport.GrossCommissionAmount / prevIntervalReport.GrossSalesAmount) * 100 || 0}
          durationDescription={durationDescription}
        />
        <StatCard
          title="AVG Order Value"
          type="dollar"
          currency={currency}
          currentVal={currentIntervalReport.GrossSalesAmount / currentIntervalReport.OrderCount || 0}
          previousVal={prevIntervalReport.GrossSalesAmount / prevIntervalReport.OrderCount || 0}
          durationDescription={durationDescription}
        />
        <StatCard
          title="Total Installs"
          type="count"
          currentVal={currentIntervalReport.ExtensionInstalls}
          previousVal={prevIntervalReport.ExtensionInstalls}
          durationDescription={durationDescription}
        />
        <StatCard
          title="Orders"
          type="count"
          currentVal={currentIntervalReport.OrderCount}
          previousVal={prevIntervalReport.OrderCount}
          durationDescription={durationDescription}
        />
        <StatCard
          title="Clicks"
          type="count"
          currentVal={currentIntervalReport.WildlinkClicks}
          previousVal={prevIntervalReport.WildlinkClicks}
          durationDescription={durationDescription}
        />
      </div>
    </div>
  );
};

export default StatCards;
