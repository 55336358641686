import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';

import { toast } from 'react-toastify';
import RedirectToPath from 'components/Route/RedirectToPath';
import UsersTable from 'components/UsersTable/UsersTable';
import { findErrorMessage } from 'helpers';
import { hasWildfirePermissionsSelector, userRolesSelector } from 'helpers/auth0';
import { usePathParam } from 'hooks/usePathParam';
import { useInviteApplicationMemberMutation } from 'reduxState/store/user/api';

import './UserManagement.scss';
import { PermissionsRole } from 'reduxState/store/user/types';

const UserManagement = () => {
  const [role, setRole] = useState<PermissionsRole>('user');
  const [email, setEmail] = useState('');

  const adminAppId = usePathParam('adminAppId');
  const { user } = useAuth0();

  const [inviteUser, invitation] = useInviteApplicationMemberMutation();

  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const isOwner = userRolesSelector(user).includes('owner');

  if (!isOwner && !hasWfPermissions) {
    return <RedirectToPath path="/" />;
  }

  const handleInviteUser = async () => {
    try {
      // only need to give the permissions based role - the application role will be assigned on the backend
      await inviteUser({ appId: adminAppId, permissionRoles: [role], email }).unwrap();
      toast.success('Success');
      setEmail('');
    } catch (error) {
      toast.error(findErrorMessage(error));
    }
  };

  return (
    <div>
      <h2>User Management</h2>
      <div className="user-management-wrapper">
        <div className="flex items-center space-x-4">
          <FormControl className="w-[500px]">
            <TextField
              variant="outlined"
              type="email"
              value={email}
              placeholder="Invite by email..."
              size="small"
              onChange={e => setEmail(e.target.value)}
            />
          </FormControl>
          <Select defaultValue="user" size="small" onChange={e => setRole(e.target.value as PermissionsRole)}>
            <MenuItem value="user">User</MenuItem>
            {hasWfPermissions && (
              <MenuItem value="owner" disabled={!hasWfPermissions}>
                Admin
              </MenuItem>
            )}
          </Select>
          <Button
            size="small"
            className="button-orange"
            onClick={() => handleInviteUser()}
            disabled={invitation.isLoading}
          >
            Invite
          </Button>
        </div>
        <UsersTable />
      </div>
    </div>
  );
};

export default UserManagement;
