import {
  Commission,
  CommissionHistoryDownloadData,
  CreateCommissionDownloadArgs,
  DisplayCommission,
  GetAllCommissionHistoryDownloadsArgs,
  GetAppGroupCommissionsQueryArgs,
  GetCommissionDownloadJobArgs,
  GetCommissionsQueryArgs,
  GetSendCommissionCallbackQueryArgs,
} from './types';
import { getDisplayCommissions } from './utils';
import api from '../api/slice';

const commissionApi = api.injectEndpoints({
  endpoints: builder => ({
    getCommissions: builder.query<
      { commissions: Commission[]; displayCommissions: DisplayCommission[] },
      GetCommissionsQueryArgs
    >({
      query: ({ appId, filters }) => {
        // TODO: need help testing this better - advanced search?  See server logic
        const params = new URLSearchParams([
          ['pagenumber', filters.pageNumber.toString()],
          ['pagesize', filters.pageSize.toString()],
          ['sortby', 'EventDate'],
          ['q', `EventDate:>"${filters.startDate}" EventDate:<"${filters.endDate}" ${filters.searchQueries}`.trim()],
        ]);

        return {
          url: `${appId}/commission/search/v5?${params.toString()}`,
        };
      },
      transformResponse: (response: { Commissions: Commission[] }) => ({
        commissions: response.Commissions,
        displayCommissions: getDisplayCommissions(response.Commissions),
      }),
    }),
    getAppGroupCommissions: builder.query<
      { commissions: Commission[]; displayCommissions: DisplayCommission[] },
      GetAppGroupCommissionsQueryArgs
    >({
      query: ({ adminId, filters }) => {
        const params = new URLSearchParams([
          ['pagenumber', filters.pageNumber.toString()],
          ['pagesize', filters.pageSize.toString()],
          ['sortby', 'EventDate'],
          ['q', `EventDate:>"${filters.startDate}" EventDate:<"${filters.endDate}" ${filters.searchQueries}`.trim()],
          ['ids', filters.ids],
        ]);
        return {
          url: `${adminId}/commission/search/v4?${params.toString()}`,
        };
      },
      transformResponse: (response: { Commissions: Commission[] }) => ({
        commissions: response.Commissions,
        displayCommissions: getDisplayCommissions(response.Commissions),
      }),
    }),
    sendCommissionCallback: builder.query<Commission, GetSendCommissionCallbackQueryArgs>({
      query: ({ appId, commissionId }) => `${appId}/commission/${commissionId}/callback`,
    }),
    getCommissionDownload: builder.query<any, GetCommissionDownloadJobArgs>({
      query: ({ appId, downloadId }) => ({
        url: `${appId}/commission/download/${downloadId}`,
        responseHandler: 'text',
      }),
    }),
    getAllCommissionDownloads: builder.query<CommissionHistoryDownloadData[], GetAllCommissionHistoryDownloadsArgs>({
      query: ({ appId }) => ({
        url: `${appId}/commission/download`,
      }),
      providesTags: ['commissionDownloads'],
    }),
    createCommissionDownload: builder.mutation<{ DownloadJobID: number }, CreateCommissionDownloadArgs>({
      query: ({ appId, userToken, queryString }) => {
        return {
          url: `${appId}/commission/download`,
          method: 'POST',
          body: {
            applicationId: appId,
            userToken,
            queryString,
          },
        };
      },
      invalidatesTags: ['commissionDownloads'],
    }),
  }),
});

export const {
  useGetCommissionsQuery,
  useGetCommissionDownloadQuery,
  useGetAllCommissionDownloadsQuery,
  useLazyGetCommissionsQuery,
  useLazyGetAppGroupCommissionsQuery,
  useLazySendCommissionCallbackQuery,
  useLazyGetCommissionDownloadQuery,
  useLazyGetAllCommissionDownloadsQuery,
  useCreateCommissionDownloadMutation,
} = commissionApi;
export default commissionApi;
