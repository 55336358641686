import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { GetApplicationsPerformanceSummaryArgs } from './types';
import {
  PerformanceSummaryReport,
  PerformanceSummaryReportSumByApp,
  flattenAppStats,
  getPerformanceSummarySumByApp,
} from './utils';
import api from '../api/slice';
import { AppStats } from '../performanceSummary/types';

const statsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAppsStats: builder.query<
      {
        appStats: AppStats[];
        flattenedAppStats: PerformanceSummaryReport[];
        statsByApp: PerformanceSummaryReportSumByApp[];
      },
      GetApplicationsPerformanceSummaryArgs
    >({
      query: ({ adminAppId, filters }) => {
        const params = new URLSearchParams([
          ...filters.application_ids.map(id => ['application_id', id]),
          ['sort_by', filters.sort_by],
          ['sort_order', filters.sort_order],
          ['group_by', filters.group_by],
          ['start_date', zonedTimeToUtc(startOfDay(new Date(filters.start_date)), 'UTC').toISOString()],
          ['end_date', zonedTimeToUtc(endOfDay(new Date(filters.end_date)), 'UTC').toISOString()],
        ]);

        return {
          url: `${adminAppId}/performance?${params.toString()}`,
        };
      },
      transformResponse: (response: AppStats[], meta, args) => {
        return {
          appStats: response,
          flattenedAppStats: flattenAppStats(response, args.filters.sort_order, args.filters.sort_by! || '') || [],
          statsByApp:
            getPerformanceSummarySumByApp(response, args.filters.sort_order, args.filters.sort_by! || '') || [],
        };
      },
    }),
  }),
});

export const { useGetAppsStatsQuery } = statsApi;
export default statsApi;
