import { getCampaignsData } from './helpers';
import { BoostedOffer, CampaignsData, GetBoostedOffersQueryArgs, NewCampaign, EditedCampaign } from './types';
import api from '../api/slice';

const boostedOffersApi = api.injectEndpoints({
  endpoints: builder => ({
    getBoostedOffers: builder.query<
      { boostedOffers: BoostedOffer[]; campaignsData: CampaignsData },
      GetBoostedOffersQueryArgs
    >({
      query: args => {
        const params = new URLSearchParams([
          ...args.applicationIds.map(appId => ['application_id', appId]),
          ['sort_by', args.sortBy],
          ['sort_order', args.sortOrder],
        ]);

        return {
          url: `${args.adminAppId}/boosted-offer?${params.toString()}`,
        };
      },
      providesTags: ['boostedOffers'],
      transformResponse: (response: BoostedOffer[]) => {
        return {
          boostedOffers: response,
          campaignsData: getCampaignsData(response),
        };
      },
    }),
    createBoostedOfferCampaigns: builder.mutation<any, { campaigns: NewCampaign[] }>({
      query: ({ campaigns }) => ({
        url: `boosted-offer`,
        method: 'POST',
        body: campaigns,
      }),
      invalidatesTags: ['boostedOffers'],
    }),
    editBoostedOfferCampaign: builder.mutation<any, { campaign: EditedCampaign }>({
      query: ({ campaign }) => ({
        url: `boosted-offer/${campaign.ID}`,
        method: 'PUT',
        body: campaign,
      }),
      invalidatesTags: ['boostedOffers'],
    }),
    deleteBoostedOfferCampaign: builder.mutation<void, number>({
      query: campaignId => ({
        url: `boosted-offer/${campaignId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['boostedOffers'],
    }),
  }),
});

export const {
  useGetBoostedOffersQuery,
  useCreateBoostedOfferCampaignsMutation,
  useEditBoostedOfferCampaignMutation,
  useDeleteBoostedOfferCampaignMutation,
} = boostedOffersApi;
export default boostedOffersApi;
