import { useParams } from 'react-router';

type Options = {
  shouldThrow?: boolean;
};

const defaultOptions = {
  shouldThrow: true,
} as const;

/**
 * Hook to get URL path parameters. Path parameter name is case-sensitive to how it was defined in the route.
 *
 * @example
 * // Route: /apps/:appId/settings
 * // URL: /apps/123/settings
 *
 * // Default behavior - throws if parameter not found
 * const appId = usePathParam('appId');
 * console.log(appId); // "123"
 *
 * // With shouldThrow: false - returns tuple with existence flag
 * const [appId, exists] = usePathParam('appId', { shouldThrow: false });
 * console.log(appId, exists); // "123", true
 *
 *
 * // When parameter doesn't exist
 * const [appId, exists] = usePathParam('typoAppId', { shouldThrow: false });
 * console.log(appId, exists); // "", false
 */
export function usePathParam(pathParam: string, options?: Options & { shouldThrow?: true }): string;
export function usePathParam(pathParam: string, options?: Options & { shouldThrow: false }): [string, boolean];
export function usePathParam(pathParam: string, options: Options = defaultOptions): string | [string, boolean] {
  pathParam = pathParam.replace(':', ''); // :appId or appId is okay

  const params = useParams();

  const pathParamValue = params[pathParam];

  if (!options.shouldThrow) {
    return [pathParamValue ?? '', !!pathParamValue];
  }

  if (!pathParamValue) {
    throw new Error(`path parameter ${pathParam} not found`);
  }

  return pathParamValue;
}
