import {
  seaCountryCodeValues,
  unitedStateCountryCodeValues,
  europeCountryCodeValues,
  allCountryCodeValues,
  GroupedCountries,
} from 'constants/countryCodes';
import { DropdownSelectOption } from 'components/DropdownSearchSelect/DropdownSearchSelect';
import { AdminApplication } from 'reduxState/store/application/types';
import { Policy } from 'reduxState/store/applicationManagement/types';
import { Split } from 'reduxState/store/applicationManagement/types';
import { excludedSplitPolicies, enhancedSplitPolicies } from '../constants';

export interface SplitPolicyItem {
  name: string;
  WILDFIRE?: {
    part: string;
    percentage: string;
  };
  DEVICE?: {
    part: string;
    percentage: string;
  };
  APPLICATION?: {
    part: string;
    percentage: string;
  };

  [key: string]: SplitPolicyItem[keyof SplitPolicyItem];
}

export const generateSplitPolicyOptions = (splitPolicies: Policy[]): DropdownSelectOption[] => {
  const filteredSplitPolicies = splitPolicies.filter(item => !excludedSplitPolicies.has(item.Name));
  const splitPolicyArray = filteredSplitPolicies.map(
    (policy: Policy): SplitPolicyItem => {
      const { Name, Splits } = policy;
      const newSplitPolicyObject: SplitPolicyItem = {
        name: Name,
      };
      Splits.forEach((split: Split) => {
        if (!newSplitPolicyObject[split.Part]) {
          newSplitPolicyObject[split.Part] = {
            percentage: typeof split.Percentage === 'string' ? split.Percentage : split.Percentage.toString(),
            part: split.Part,
          };
        }
      });
      return newSplitPolicyObject;
    },
  );

  const splitPolicyOptions = splitPolicyArray.map(
    (item: SplitPolicyItem): DropdownSelectOption => {
      const { name } = item;

      const regex = /Enhanced/i;
      const isPolicyEnhanced = regex.test(name);

      const wildfireSplit = item['WILDFIRE'];
      const applicationSplit = item['APPLICATION']!;
      const deviceSplit = item['DEVICE']!;

      let labelString = '';
      if (wildfireSplit) labelString += `${wildfireSplit.percentage} Wildfire`;
      if (applicationSplit) labelString += ` | ${applicationSplit.percentage} Application`;
      if (deviceSplit) labelString += ` | ${deviceSplit.percentage} Device`;
      if (isPolicyEnhanced || enhancedSplitPolicies.has(name)) labelString += ` (Enhanced)`;

      return {
        label: `${labelString} - [${name}]`,
        value: name,
      };
    },
  );
  return splitPolicyOptions;
};

export const generateAdminAppOptions = (adminApplications: AdminApplication[]): DropdownSelectOption[] => {
  return adminApplications.map((adminApplication: AdminApplication) => {
    const adminName = adminApplication.Name;
    const label = adminName.replace(/\s*Admin\s*/g, ' ').trim();
    return {
      value: {
        name: label,
        id: adminApplication.ID,
      },
      label: `${adminApplication.ID} - ${label}`,
      group: 'Companies',
    };
  });
};

export const addGroupedCountryValues = (countriesArray: string[]): string[] => {
  let newCountriesArray: string[] = [];

  if (countriesArray.length === 0) {
    return [];
  }

  const countriesArraySet = new Set(countriesArray);
  if (countriesArraySet.has('All Countries')) {
    return [...new Set(allCountryCodeValues)];
  }

  countriesArray.forEach((countryValue: string) => {
    switch (countryValue) {
      case GroupedCountries.US: {
        newCountriesArray = [...newCountriesArray, ...unitedStateCountryCodeValues];
        break;
      }
      case GroupedCountries.EUROPE: {
        newCountriesArray = [...newCountriesArray, ...europeCountryCodeValues];
        break;
      }
      case GroupedCountries.SOUTH_EAST_ASIA: {
        newCountriesArray = [...newCountriesArray, ...seaCountryCodeValues];
        break;
      }
      default: {
        newCountriesArray = [...newCountriesArray, countryValue];
        break;
      }
    }
  });

  return [...new Set(newCountriesArray)];
};
